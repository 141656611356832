/* ====================================================
HTML
==================================================== */
html {
    font-size: 62.5%;
}


body {
	color: #333;
	@include font-fm($font-jp, 400); 
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
	background: #fff;
}

a {
	display: inline-block;
	color: inherit;
    text-decoration: none;
    outline: none;
}








