@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600&family=Noto+Sans+JP:wght@300;400;500;700&family=Poppins:wght@400;600&family=Shippori+Mincho:wght@400;500;600&display=swap");
@media screen and (min-width: 835px) {
  .mbpc-0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
/* A Modern CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  scroll-behavior: auto;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: inline-block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* ====================================================
HTML
==================================================== */
html {
  font-size: 62.5%;
}

body {
  color: #333;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  background: #fff;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  outline: none;
}

/* ====================================================
Layout
==================================================== */
@media screen and (max-width: 1024px) {
  .wrapper {
    padding-top: 70px;
  }
}

.inner {
  width: 90%;
  margin: 0 auto;
  max-width: 1000px;
}
@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    padding: 0 5%;
  }
}

.l-pageTtl {
  text-align: center;
  position: relative;
  background: url(../img/common/bg-pageTtl.jpg) repeat center center;
  background-size: 100%;
}
.l-pageTtl__ttl {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .l-pageTtl__ttl {
    font-size: 25px;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 640px) {
  .l-pageTtl__image {
    width: 85%;
  }
}

/* ====================================================
Control
==================================================== */
.c-fc--ff {
  color: #fff;
}

.c-ft--en {
  font-family: "Poppins", sans-serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left;
}

.c-val--md {
  vertical-align: middle;
}
.c-val--top {
  vertical-align: top;
}

.c-scroll-box {
  padding-bottom: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.c-scroll-box-y {
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media screen and (max-width: 834px) {
  .c-scroll-box-sp {
    padding-bottom: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.c-img-of {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center", sans-serif;
}

.c-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.c-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1875;
}
@media screen and (max-width: 640px) {
  .c-txt__01 {
    line-height: 1.8;
  }
}
.c-txt__02 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
}
@media screen and (max-width: 640px) {
  .c-txt__02 {
    line-height: 1.8;
  }
}

.c-ttl__01 {
  font-size: 48px;
  font-size: 4.8rem;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-ttl__01 {
    font-size: 28px;
    font-size: 2.8rem;
    font-size: max(28px, 7.1942446043vw);
  }
}
.c-ttl__01-cap {
  display: block;
  margin-top: 13px;
  font-size: 18px;
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .c-ttl__01-cap {
    font-size: 15px;
    font-size: 1.5rem;
  }
}
.c-ttl__03 {
  text-align: center;
}
.c-ttl__03-en {
  color: #46aef7;
  background: -webkit-linear-gradient(0deg, #1dd5e6 0%, #46aef7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
@media screen and (max-width: 640px) {
  .c-ttl__03-en {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
.c-ttl__03-jp {
  display: block;
  margin-top: 10px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 42px;
  font-size: 4.2rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 640px) {
  .c-ttl__03-jp {
    font-size: 32px;
    font-size: 3.2rem;
  }
}
.c-ttl__03-jp2 {
  display: block;
  margin-top: 10px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.44;
}
@media screen and (max-width: 640px) {
  .c-ttl__03-jp2 {
    font-size: 25px;
    font-size: 2.5rem;
  }
}
.c-ttl__03-jp3 {
  display: block;
  margin-top: 10px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.44;
}
@media screen and (max-width: 640px) {
  .c-ttl__03-jp3 {
    font-size: 24px;
    font-size: 2.4rem;
  }
}
.c-ttl__03-cap {
  display: block;
  margin: 25px 0;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 834px) {
  .c-ttl__03-cap {
    margin: 15px 0;
  }
}

.c-btn__shine {
  position: relative;
  overflow: hidden;
}
.c-btn__shine:before {
  content: "";
  position: absolute;
  top: 0;
  left: -75%;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}
.c-btn__shine:hover:before {
  animation: shine 0.7s;
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.c-btn__01 {
  width: 100%;
  max-width: 310px;
  color: #fff;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  line-height: 65px;
  text-align: center;
  background: #3bb8f3;
  border-radius: 40px;
}
@media screen and (min-width: 835px) {
  .c-btn__01 {
    transition: all 0.2s;
  }
  .c-btn__01:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 50px;
  }
}

/* ====================================================
Utility
==================================================== */
@media screen and (max-width: 834px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media screen and (min-width: 835px) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

.js-u__fadein {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1000ms;
}

.js-u__fadein.is-visible {
  opacity: 1;
  transform: translate(0, 0);
}

.js-u__topfadein {
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 50px);
  transition: all 1000ms;
}

.js-u__topfadein.is-visible {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.js-tab-panel {
  display: none;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
}
.js-tab-panel.is-show {
  display: block;
  visibility: visible;
  opacity: 1;
}

.eachTextAnime span {
  opacity: 0;
}

.eachTextAnime.appeartext span {
  animation: text_anime_on 1s ease-out forwards;
}

.eachTextAnime2 span {
  opacity: 0;
}

.eachTextAnime2.appeartext span {
  animation: text_anime_on 1s ease-out forwards;
}

@keyframes text_anime_on {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* ====================================================
Module
==================================================== */
/* ====================================================
Header
==================================================== */
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  z-index: 1000;
  transition: 0.3s;
}
@media screen and (max-width: 1024px) {
  .main-header {
    padding: 0 0 0 10px;
    height: 70px;
  }
}
.main-header__inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
}
.main-header__logo {
  display: inline-block;
  padding: 38px 0;
  width: 150px;
  height: 150px;
  text-align: center;
  background: #fff;
  transition: 0.3s;
}
@media screen and (max-width: 1024px) {
  .main-header__logo {
    padding: 10px 0;
    width: 70px;
    height: 70px;
    z-index: 100;
  }
  .main-header__logo img {
    width: 40px;
  }
}
.main-header__navGlobal {
  padding-top: 40px;
  width: calc(100% - 150px);
}
@media screen and (max-width: 1024px) {
  .main-header__navGlobal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 70px;
    width: 100%;
    text-align: center;
  }
}
.main-header__navGlobal.show {
  display: block;
  background: #fff;
  height: 100vh;
}
@media screen and (min-width: 1025px) {
  .main-header__menu {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.main-header__menu-item {
  margin-left: 2em;
  font-size: 15px;
  font-size: 1.5rem;
}
@media screen and (max-width: 1200px) {
  .main-header__menu-item {
    margin-left: 1em;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__menu-item {
    margin: 20px 0;
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.main-header__menu-item--twitter {
  margin: 0 3em;
}
.main-header__menu-item--twitter a {
  color: #3bb8f3;
  font-size: 16px;
  font-size: 1.6rem;
}
.main-header__link {
  display: inline-block;
}
@media screen and (min-width: 835px) {
  .main-header__link {
    transition: all 0.2s;
  }
  .main-header__link:hover {
    opacity: 0.7;
  }
}
.main-header__mobile-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 70px;
  height: 70px;
  background: #46aef7;
  transition: background 0.5s;
  z-index: 102;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .main-header__mobile-icon {
    display: block;
  }
}
.main-header__mobile-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  width: 26px;
  height: 2px;
  font-size: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.3s;
}
.main-header__mobile-icon span:before, .main-header__mobile-icon span:after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  content: "";
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  border-radius: 3px;
}
.main-header__mobile-icon span:before {
  top: -5px;
}
.main-header__mobile-icon span:after {
  bottom: -5px;
}
.main-header__mobile-icon.mobile-close span {
  background: transparent;
}
.main-header__mobile-icon.mobile-close span:before, .main-header__mobile-icon.mobile-close span:after {
  width: 100%;
}
.main-header__mobile-icon.mobile-close span:before {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
  top: 0;
}
.main-header__mobile-icon.mobile-close span:after {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
  bottom: 0;
}
.main-header.is-active {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 1025px) {
  .main-header.is-active {
    height: 100px;
  }
  .main-header.is-active .main-header__logo {
    padding: 13px 0;
    width: 100px;
    height: 100px;
  }
  .main-header.is-active .main-header__logo img {
    width: 60px;
  }
}

/* ====================================================
Footer
==================================================== */
.main-footer__function {
  padding: 80px 0 70px;
  color: #fff;
  background: #1dd5e6;
  background: linear-gradient(90deg, #1dd5e6 0%, #46aef7 100%);
}
@media screen and (max-width: 640px) {
  .main-footer__function {
    padding: 40px 0 10px;
  }
}
.main-footer__function-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.main-footer__function-clm {
  padding: 74px 2%;
  width: 50%;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .main-footer__function-clm {
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
  }
}
@media screen and (min-width: 641px) {
  .main-footer__function-clm:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
}
.main-footer__function-txt {
  margin-bottom: 40px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.4;
  font-weight: 500;
}
@media screen and (max-width: 990px) {
  .main-footer__function-txt {
    font-size: 17px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 640px) {
  .main-footer__function-txt {
    margin-bottom: 15px;
  }
}
.main-footer__function-btn {
  display: inline-block;
  width: 100%;
  max-width: 390px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 92px;
  font-weight: 500;
  border-radius: 50px;
  box-shadow: 4px 0 7px rgba(0, 0, 0, 0.03);
  background: #fff;
}
@media screen and (min-width: 835px) {
  .main-footer__function-btn {
    transition: all 0.2s;
  }
  .main-footer__function-btn:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 640px) {
  .main-footer__function-btn {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 60px;
  }
}
.main-footer__function-btn > span {
  color: #46aef7;
  background: -webkit-linear-gradient(0deg, #1dd5e6 0%, #46aef7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.main-footer__copyright {
  padding: 35px 0;
  color: #c3d4d8;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .main-footer__copyright {
    padding: 20px 0;
  }
}

/* ====================================================
Pages
==================================================== */
/* ====================================================
PAGE INDEX
==================================================== */
#page-index {
  padding-bottom: 84px;
}
@media screen and (max-width: 640px) {
  #page-index {
    padding-bottom: 65px;
  }
}

.top-lead {
  padding: 198px 0 0;
  background: #f3f7f9;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .top-lead {
    padding: 80px 0 10px;
  }
}
.top-lead__ttl {
  text-align: center;
}
.top-lead__ttl-en {
  display: inline-block;
  color: #3bb8f3;
  font-size: 100px;
  font-size: 10rem;
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 0.7;
}
@media screen and (max-width: 640px) {
  .top-lead__ttl-en {
    font-size: 55px;
    font-size: 5.5rem;
  }
}
.top-lead__ttl-jp {
  display: block;
  margin-top: 15px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 60px;
  font-size: 6rem;
  letter-spacing: -0.1em;
}
@media screen and (max-width: 640px) {
  .top-lead__ttl-jp {
    font-size: 40px;
    font-size: 4rem;
  }
}
.top-lead__ttl-jp > span {
  color: #3bb8f3;
}
.top-lead__txt {
  margin: 170px 0;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.5625;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .top-lead__txt {
    margin: 40px 0 60px;
    font-size: 22px;
    font-size: 2.2rem;
  }
}
.top-lead__block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 90px;
}
@media screen and (max-width: 834px) {
  .top-lead__block {
    margin-bottom: 45px;
  }
}
.top-lead__block .top-lead__block-contentL, .top-lead__block .top-lead__block-contentR {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .top-lead__block .top-lead__block-contentL, .top-lead__block .top-lead__block-contentR {
    width: 100%;
  }
}
.top-lead__block .top-lead__block-imageR, .top-lead__block .top-lead__block-imageL {
  width: calc(50% + 40px);
}
@media screen and (max-width: 834px) {
  .top-lead__block .top-lead__block-imageR, .top-lead__block .top-lead__block-imageL {
    margin-bottom: 20px;
    width: 100%;
  }
}
@media screen and (min-width: 835px) {
  .top-lead__block-imageL {
    padding: 0 40px 0 0;
    margin: 0 0 0 -40px;
  }
}
@media screen and (min-width: 835px) {
  .top-lead__block-imageR {
    padding: 0 0 0 40px;
    margin: 0 -40px 0 0;
    order: 2;
  }
}
@media screen and (max-width: 834px) {
  .top-lead__block-imageR {
    order: 1;
  }
}
.top-lead__block-contentL {
  order: 1;
}
@media screen and (max-width: 834px) {
  .top-lead__block-contentL {
    order: 2;
  }
}
.top-lead__block-en {
  margin-bottom: 10px;
  color: #3bb8f3;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}
@media screen and (max-width: 640px) {
  .top-lead__block-en {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.top-lead__block-en .en01 {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 640px) {
  .top-lead__block-en .en01 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.top-lead__block-en .en02 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 26px;
  font-size: 2.6rem;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 640px) {
  .top-lead__block-en .en02 {
    font-size: 20px;
    font-size: 2rem;
  }
}
.top-lead__block-jp {
  margin-bottom: 15px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.5;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .top-lead__block-jp {
    margin-bottom: 15px;
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.top-questionnaire {
  padding: 158px 0 100px;
  text-align: center;
  background: #1dd5e6;
  background: linear-gradient(90deg, #1dd5e6 0%, #46aef7 100%);
}
@media screen and (max-width: 834px) {
  .top-questionnaire {
    padding: 60px 0;
  }
}
.top-questionnaire .top-questionnaire__block02, .top-questionnaire .top-questionnaire__block01 {
  background: #fff;
  border-radius: 20px;
}
.top-questionnaire__lead {
  margin: 35px 0 90px;
  color: #fff;
}
@media screen and (max-width: 834px) {
  .top-questionnaire__lead {
    margin: 25px 0 40px;
  }
}
.top-questionnaire__block-ttl {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 24px;
  font-size: 2.4rem;
}
@media screen and (max-width: 640px) {
  .top-questionnaire__block-ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}
.top-questionnaire__block-ttl span {
  display: inline-block;
  padding: 18px 0 18px 70px;
  text-align: left;
  background: url(../img/index/icn-q.png) no-repeat left center;
  background-size: 60px;
}
@media screen and (max-width: 640px) {
  .top-questionnaire__block-ttl span {
    padding: 15px 0 15px 55px;
    background-size: 50px;
  }
}
.top-questionnaire__block-note {
  margin-top: 5px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.3;
}
.top-questionnaire__block01 {
  padding-top: 50px;
  padding-bottom: 30px;
  margin-bottom: 65px;
}
@media screen and (max-width: 834px) {
  .top-questionnaire__block01 {
    padding-top: 30px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 640px) {
  .top-questionnaire__block01 {
    padding: 30px 5%;
  }
}
.top-questionnaire__block01 img {
  position: relative;
  right: -20px;
}
@media screen and (max-width: 834px) {
  .top-questionnaire__block01 img {
    display: inline-block;
    margin-top: 20px;
    right: -2%;
  }
}
.top-questionnaire__block02 {
  padding: 70px 4% 40px;
}
@media screen and (max-width: 834px) {
  .top-questionnaire__block02 {
    padding: 30px 5% 10px;
  }
}
.top-questionnaire__block02 .top-questionnaire__block-ttl {
  margin-bottom: 60px;
}
@media screen and (max-width: 834px) {
  .top-questionnaire__block02 .top-questionnaire__block-ttl {
    margin-bottom: 30px;
  }
}
.top-questionnaire__block02 img {
  width: 100%;
  max-width: 1040px;
}
@media screen and (max-width: 834px) {
  .top-questionnaire__block02 img {
    width: 80%;
  }
}

.top-team {
  padding: 130px 0;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .top-team {
    padding: 60px 0 30px;
  }
}
.top-team__inner {
  position: relative;
}
.top-team__bgImage01 {
  position: absolute;
  bottom: 140px;
  left: calc((1500px - 100%) / 2 * -1);
  width: 354px;
  z-index: -1;
}
@media screen and (max-width: 1200px) {
  .top-team__bgImage01 {
    width: 29.5vw;
    bottom: 23%;
    left: calc((100vw - 100%) / 2 * -1);
  }
}
@media screen and (max-width: 834px) {
  .top-team__bgImage01 {
    bottom: 55%;
    min-width: 200px;
  }
}
.top-team__bgImage02 {
  position: absolute;
  bottom: 170px;
  right: calc((1500px - 100%) / 2 * -1);
  width: 310px;
  z-index: -1;
}
@media screen and (max-width: 1200px) {
  .top-team__bgImage02 {
    width: 25.833vw;
    bottom: 20%;
    right: calc((100vw - 100%) / 2 * -1);
  }
}
@media screen and (max-width: 834px) {
  .top-team__bgImage02 {
    min-width: 200px;
    bottom: 25%;
  }
}
.top-team__ttl span {
  color: #46aef7;
  background: -webkit-linear-gradient(0deg, #1dd5e6 0%, #46aef7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.top-team__mainImage {
  display: block;
  margin: 70px 0;
  border-radius: 20px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .top-team__mainImage {
    margin: 30px 0;
  }
}
.top-team__block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.top-team__clm {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 49%;
}
@media screen and (max-width: 834px) {
  .top-team__clm {
    margin-bottom: 30px;
    width: 100%;
  }
}
.top-team__clm-img {
  width: 40.0816%;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .top-team__clm-img {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
}
.top-team__clm-img img {
  border-radius: 20px;
}
@media screen and (max-width: 640px) {
  .top-team__clm-img img {
    width: 200px;
  }
}
.top-team__clm-cont {
  width: 55.78%;
}
@media screen and (max-width: 640px) {
  .top-team__clm-cont {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .top-team__clm-ttl {
    text-align: center;
  }
}
.top-team__clm-ttl-jp {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 32px;
  font-size: 3.2rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 640px) {
  .top-team__clm-ttl-jp {
    font-size: 24px;
    font-size: 2.4rem;
  }
}
.top-team__clm-ttl-en {
  display: block;
  margin: 10px 0;
  color: #3bb8f3;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
.top-team__clm-desc {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .top-team__clm-desc {
    line-height: 1.8;
  }
}

.top-work {
  padding: 105px 0 90px;
  background: url(../img/index/bg-work.jpg) repeat left center;
  background-size: 100%;
}
@media screen and (max-width: 834px) {
  .top-work {
    padding: 50px 0;
  }
}
.top-work__lead {
  margin: 50px 0 65px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.619047619;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .top-work__lead {
    margin: 30px 0;
    font-size: 17px;
    font-size: 1.7rem;
  }
}
.top-work__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 50px;
}
@media screen and (max-width: 640px) {
  .top-work__list {
    margin-top: 30px;
  }
}
.top-work__list-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  width: 18.735833%;
  height: 224.83px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
}
@media screen and (max-width: 1200px) {
  .top-work__list-item {
    height: 16.8616vw;
  }
}
@media screen and (max-width: 834px) {
  .top-work__list-item {
    width: 32%;
    height: 28.799vw;
  }
}
@media screen and (max-width: 640px) {
  .top-work__list-item {
    width: 49%;
    height: 44.098vw;
  }
}
.top-work__list-item-cap {
  margin-top: 28px;
  color: #3bb8f3;
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: 500;
}
@media screen and (max-width: 1024px) {
  .top-work__list-item-cap {
    margin-top: 10px;
  }
}
@media screen and (max-width: 640px) {
  .top-work__list-item-cap {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.top-work__list-item-icn {
  width: 94px;
}
@media screen and (max-width: 1024px) {
  .top-work__list-item-icn {
    width: 55%;
  }
}
.top-work__list-item-L {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 40px 2.5%;
  width: 49.166%;
  background: #fff;
  border-radius: 20px;
}
@media screen and (max-width: 834px) {
  .top-work__list-item-L {
    padding: 30px 3%;
    width: 100%;
  }
}
.top-work__list-item-L-icn {
  width: 138px;
}
@media screen and (max-width: 1024px) {
  .top-work__list-item-L-icn {
    width: 100px;
  }
}
@media screen and (max-width: 640px) {
  .top-work__list-item-L-icn {
    width: 90px;
  }
}
.top-work__list-item-L-desc {
  width: calc(100% - 163px);
}
@media screen and (max-width: 1024px) {
  .top-work__list-item-L-desc {
    width: calc(100% - 110px);
  }
}
@media screen and (max-width: 640px) {
  .top-work__list-item-L-desc {
    width: calc(100% - 100px);
  }
}
.top-work__list-item-L-cap {
  margin-bottom: 5px;
  color: #3bb8f3;
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .top-work__list-item-L-cap {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.top-work__list-item-L-desc {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}

.top-product {
  position: relative;
  padding: 116px 0 100px;
  background: url(../img/index/bg-product.jpg) repeat-x center bottom;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .top-product {
    padding: 60px 0 50px;
  }
}
.top-product__ttl {
  margin: 0 0 50px;
}
@media screen and (max-width: 640px) {
  .top-product__ttl {
    margin: 0 0 30px;
  }
}
.top-product__lead {
  margin: 50px 0 65px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.619047619;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .top-product__lead {
    margin: 30px 0;
    font-size: 17px;
    font-size: 1.7rem;
  }
}
.top-product__block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.top-product__block-image {
  width: 32.5%;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .top-product__block-image {
    margin-bottom: 30px;
    width: 100%;
  }
}
.top-product__block-content {
  padding-bottom: 40px;
  width: 61.667%;
  border-bottom: 1px solid #000;
}
@media screen and (max-width: 834px) {
  .top-product__block-content {
    padding-bottom: 30px;
    width: 100%;
  }
}
.top-product__block-label-item {
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 20px;
  padding-left: 20px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .top-product__block-label-item {
    padding-left: 15px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}
.top-product__block-label-item:before {
  position: absolute;
  left: 0;
  top: 0;
  color: #3bb9f2;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.top-product__block-ttl {
  margin: 0 0 20px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.0625;
}
@media screen and (max-width: 834px) {
  .top-product__block-ttl {
    font-size: 24px;
    font-size: 2.4rem;
  }
}
.top-product__block-desc {
  margin-bottom: 20px;
}
.top-product__speclist-item {
  margin-bottom: 10px;
  color: #aaa;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
}
.top-product__slide-wrap {
  margin-bottom: 20px;
  position: relative;
}
.top-product__slide-wrap .slick-arrows {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 960px;
  width: 96%;
}
.top-product__slide-wrap .slick-arrows .slick-arrow {
  position: relative;
  width: 65px;
  height: 65px;
}
@media screen and (max-width: 640px) {
  .top-product__slide-wrap .slick-arrows .slick-arrow {
    width: 45px;
    height: 45px;
  }
}
.top-product__slide-wrap .slick-arrows .slick-arrow.slick-prev:before {
  left: 10px;
  border-left: 2px solid #e2e3e3;
  transform: rotate(-45deg);
}
.top-product__slide-wrap .slick-arrows .slick-arrow.slick-next:before {
  right: 10px;
  border-right: 2px solid #e2e3e3;
  transform: rotate(45deg);
}
.top-product__slide-wrap .slick-arrows .slick-arrow:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 48px;
  height: 48px;
  top: 10px;
  text-align: center;
  border-top: 2px solid #e2e3e3;
}
@media screen and (max-width: 640px) {
  .top-product__slide-wrap .slick-arrows .slick-arrow:before {
    width: 35px;
    height: 35px;
  }
}
.top-product__slide {
  margin-top: 225px;
  padding: 55px 0 0;
}
@media screen and (max-width: 1200px) {
  .top-product__slide {
    padding: 4.583vw 0 0;
  }
}
@media screen and (max-width: 834px) {
  .top-product__slide {
    margin-top: 60px;
  }
}
.top-product__slide-item {
  padding: 0 2vw;
}
.top-product__slide-item img {
  border-radius: 20px;
}
.top-product__slide .slick-list {
  overflow: visible;
}
.top-product__slide .slick-list img {
  display: block;
  width: auto;
  max-width: 770px;
  transform: scale(0.8);
  transition: 0.3s;
}
@media screen and (max-width: 1200px) {
  .top-product__slide .slick-list img {
    max-width: 64.166vw;
  }
}
.top-product__slide .slick-center img {
  width: 100%;
  transform: scale(1);
}
.top-product .slick-dots {
  margin: 0 auto;
  max-width: 750px;
  text-align: right;
  bottom: -20px;
  right: 0;
  left: 0;
}
@media screen and (max-width: 990px) {
  .top-product .slick-dots {
    max-width: 90%;
  }
}
.top-product .slick-dots li {
  margin: 0 4px;
}
.top-product .slick-dots li button {
  width: 12px;
  height: 12px;
  background: #dddddd;
  border-radius: 50%;
}
.top-product .slick-dots li button:before {
  display: none;
}
.top-product .slick-dots li.slick-active button {
  background: #3bb8f3;
}

.top__equipment {
  padding-top: 80px;
}
@media screen and (max-width: 834px) {
  .top__equipment {
    padding-top: 40px;
  }
}
.top__equipment-lead {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .top__equipment-lead {
    margin-bottom: 40px;
  }
}
.top__equipment-ttl {
  text-align: left;
  width: 47%;
}
@media screen and (max-width: 834px) {
  .top__equipment-ttl {
    margin-bottom: 15px;
    width: 100%;
  }
}
.top__equipment-desc {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .top__equipment-desc {
    width: 100%;
  }
}
.top__equipment-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.top__equipment-clm {
  width: 46.66%;
}
@media screen and (max-width: 834px) {
  .top__equipment-clm {
    width: 100%;
  }
  .top__equipment-clm:not(:last-child) {
    margin-bottom: 30px;
  }
}
.top__equipment-list {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.3;
}
.top__equipment-list-item {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}
.top__equipment-list-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  top: 5px;
  background: #fff;
  border-radius: 50%;
}
.top__equipment-list-ttl {
  position: relative;
  margin-bottom: 24px;
  padding-left: 24px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 834px) {
  .top__equipment-list-ttl {
    margin-bottom: 15px;
  }
}
.top__equipment-list-ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #1dd5e6 0%, #46aef7 100%);
  border-radius: 50%;
}

.top-news {
  padding: 152px 0 54px;
}
@media screen and (max-width: 834px) {
  .top-news {
    padding: 60px 0;
  }
}
.top-news__content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.top-news__ttl-block {
  margin-left: -67px;
  width: 25.88%;
  text-align: right;
}
@media screen and (max-width: 1500px) {
  .top-news__ttl-block {
    margin-left: -4.44vw;
  }
}
@media screen and (max-width: 834px) {
  .top-news__ttl-block {
    margin-left: 0;
    width: 100%;
    text-align: left;
  }
  .top-news__ttl-block img {
    display: none;
  }
}
.top-news__ttl {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 36px;
  font-size: 3.6rem;
}
@media screen and (max-width: 834px) {
  .top-news__ttl {
    font-size: 30px;
    font-size: 3rem;
  }
}
.top-news__btn {
  display: inline-block;
  margin: 30px 0 50px;
  font-size: 15px;
  font-size: 1.5rem;
}
@media screen and (max-width: 834px) {
  .top-news__btn {
    margin: 20px 0 30px;
  }
}
.top-news__btn i {
  margin-right: 5px;
  color: #1da1f2;
}
.top-news__btn span {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 1px solid #adadad;
}
.top-news__timeline {
  width: 70.166%;
  border-top: 1px solid #b8b8b8;
}
@media screen and (max-width: 834px) {
  .top-news__timeline {
    width: 100%;
  }
}
@media screen and (max-width: 834px) {
  .top-news__timeline .twitter-timeline {
    height: 400px !important;
  }
}
@media screen and (max-width: 640px) {
  .top-news__timeline .twitter-timeline {
    height: 300px !important;
  }
}

.top-company {
  padding: 120px 0 130px;
  background: url(../img/index/bg-company.jpg) repeat center top;
}
@media screen and (max-width: 834px) {
  .top-company {
    padding: 60px 0;
  }
}
.top-company__tab-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 100px;
  padding: 0 7.5%;
  border-bottom: 1px solid #ccc;
}
@media screen and (max-width: 834px) {
  .top-company__tab-list {
    margin-top: 50px;
    padding: 0;
  }
}
.top-company__tab-list-item {
  position: relative;
  padding-bottom: 25px;
  width: 25%;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .top-company__tab-list-item {
    padding-bottom: 20px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 640px) {
  .top-company__tab-list-item {
    width: 23%;
  }
  .top-company__tab-list-item:last-child {
    width: 31%;
  }
}
.top-company__tab-list-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 3px;
  display: none;
  bottom: -1px;
  left: 0;
  background: #3bb8f3;
}
.top-company__tab-list-item.active {
  color: #3bb8f3;
}
.top-company__tab-list-item.active:before {
  display: block;
}
.top-company__outline {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 60px 0 0;
}
@media screen and (max-width: 834px) {
  .top-company__outline {
    padding: 20px 0 0;
  }
}
.top-company__outline-clm {
  width: 48.33%;
}
@media screen and (max-width: 834px) {
  .top-company__outline-clm {
    width: 100%;
  }
}
.top-company__outline-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 25px 0;
  border-bottom: 1px solid #eee;
}
@media screen and (max-width: 834px) {
  .top-company__outline-block {
    padding: 20px 0;
  }
}
.top-company__outline-block-head {
  width: 104px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .top-company__outline-block-head {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.6;
  }
}
.top-company__outline-block-desc {
  width: calc(100% - 104px);
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .top-company__outline-block-desc {
    line-height: 1.6;
  }
}
.top-company__outline-block-desc .small {
  font-size: 13px;
  font-size: 1.3rem;
}
.top-company__history {
  padding-top: 70px;
}
@media screen and (max-width: 834px) {
  .top-company__history {
    padding-top: 45px;
  }
}
.top-company__history-block {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.top-company__history-block:last-of-type {
  padding-bottom: 0;
}
.top-company__history-block:last-of-type .top-company__history-block-head:before {
  display: none;
}
.top-company__history-block-head {
  position: relative;
  padding-bottom: 35px;
  padding-right: 45px;
  width: 194px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.3;
  text-align: right;
}
@media screen and (max-width: 834px) {
  .top-company__history-block-head {
    padding-right: 30px;
    width: 140px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 640px) {
  .top-company__history-block-head {
    padding-bottom: 45px;
  }
}
.top-company__history-block-head:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 100%;
  right: 9px;
  top: 4px;
  background: #ddd;
}
@media screen and (max-width: 834px) {
  .top-company__history-block-head:before {
    top: 3px;
    right: 8px;
  }
}
.top-company__history-block-head:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0;
  top: 4px;
  background: #3bb8f3;
  border-radius: 50%;
}
@media screen and (max-width: 834px) {
  .top-company__history-block-head:after {
    top: 3px;
    width: 16px;
    height: 16px;
  }
}
.top-company__history-block-desc {
  padding-left: 24px;
  width: calc(100% - 194px);
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
@media screen and (max-width: 834px) {
  .top-company__history-block-desc {
    padding-left: 15px;
    width: calc(100% - 140px);
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.3;
  }
}
.top-company__philosophy {
  padding-top: 60px;
}
@media screen and (max-width: 834px) {
  .top-company__philosophy {
    padding-top: 45px;
  }
}
.top-company__philosophy .top-company__philosophy-block02, .top-company__philosophy .top-company__philosophy-block01 {
  background: #fff;
  border-radius: 20px;
}
.top-company__philosophy-block01 {
  margin-bottom: 40px;
  padding: 40px 20px 50px;
}
@media screen and (max-width: 834px) {
  .top-company__philosophy-block01 {
    margin-bottom: 30px;
    padding: 30px 5%;
  }
}
.top-company__philosophy-block02 {
  padding: 50px 5%;
}
@media screen and (max-width: 834px) {
  .top-company__philosophy-block02 {
    padding: 30px 5%;
  }
}
.top-company__philosophy-ttl {
  margin-bottom: 45px;
}
@media screen and (max-width: 834px) {
  .top-company__philosophy-ttl {
    margin-bottom: 30px;
  }
}
.top-company__philosophy-ttl-jp {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 32px;
  font-size: 3.2rem;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 834px) {
  .top-company__philosophy-ttl-jp {
    font-size: 26px;
    font-size: 2.6rem;
  }
}
.top-company__philosophy-ttl-en {
  display: block;
  margin-top: 10px;
  color: #3bb8f3;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  font-size: 1.2rem;
}
.top-company__philosophy-txt {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 28px;
  font-size: 2.8rem;
}
@media screen and (max-width: 640px) {
  .top-company__philosophy-txt {
    font-size: 22px;
    font-size: 2.2rem;
  }
}
.top-company__philosophy-txt02 {
  margin-bottom: 25px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.3;
}
@media screen and (max-width: 640px) {
  .top-company__philosophy-txt02 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.top-company__message {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 105px;
}
@media screen and (max-width: 640px) {
  .top-company__message {
    padding-top: 45px;
  }
}
.top-company__message-ttl {
  width: 48%;
  text-align: left;
  line-height: 1.4;
}
@media screen and (max-width: 834px) {
  .top-company__message-ttl {
    margin-bottom: 30px;
    width: 100%;
  }
}
.top-company__message-desc {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .top-company__message-desc {
    width: 100%;
  }
}
.top-company__message-sign {
  margin-top: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: right;
}
.top-company__message-sign .strong {
  font-size: 21px;
  font-size: 2.1rem;
}
@media screen and (max-width: 640px) {
  .top-company__message-sign .strong {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.bnr-contact {
  position: fixed;
  right: 0;
  bottom: -5px;
  width: 255px;
  opacity: 0;
  visibility: hidden;
}
@media screen and (min-width: 835px) {
  .bnr-contact {
    transition: all 0.2s;
  }
  .bnr-contact:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 640px) {
  .bnr-contact {
    width: 200px;
  }
}
.bnr-contact.is-show {
  opacity: 1;
  visibility: visible;
}

/* ====================================================
 RECRUIT
==================================================== */
.recruit-lead {
  padding: 165px 0 175px;
}
@media screen and (max-width: 834px) {
  .recruit-lead {
    padding: 60px 0;
  }
}
.recruit-lead__ttl {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 48px;
  font-size: 4.8rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1.3;
}
@media screen and (max-width: 834px) {
  .recruit-lead__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    font-size: max(26px, 5.7553956835vw);
  }
}
.recruit-lead__txt {
  margin: 50px 0 120px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.7142857143;
}
@media screen and (max-width: 640px) {
  .recruit-lead__txt {
    margin: 30px 0 40px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.recruit__bnr {
  padding: 55px 20px;
  text-align: center;
  background: #fefefe url(../img/recruit/bg-bnr.jpg) no-repeat center center;
  background-size: 100%;
  border-radius: 20px;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .recruit__bnr {
    background: #fefefe url(../img/recruit/bg-bnr-sp.jpg) no-repeat center center;
    background-size: 100%;
  }
}
@media screen and (max-width: 640px) {
  .recruit__bnr {
    padding: 40px 20px;
  }
}
.recruit__bnr-jp {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 35px;
  font-size: 3.5rem;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .recruit__bnr-jp {
    font-size: 24px;
    font-size: 2.4rem;
  }
}
.recruit__bnr-en {
  display: block;
  margin: 20px 0;
  color: #3bb8f3;
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.recruit__bnr-link {
  padding: 0 40px;
  color: #fff;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 48px;
  font-weight: 500;
  background: #3bb8f3;
  border-radius: 30px;
}
@media screen and (max-width: 640px) {
  .recruit__bnr-link {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

/* ====================================================
 CONTACT
==================================================== */
.contact-lead {
  padding: 165px 0 115px;
}
@media screen and (max-width: 834px) {
  .contact-lead {
    padding: 40px 0;
  }
}
.contact-lead__ttl {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1.7;
}
@media screen and (max-width: 834px) {
  .contact-lead__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.contact-lead__ttl span {
  font-size: 18px;
  font-size: 1.8rem;
}
@media screen and (max-width: 834px) {
  .contact-lead__ttl span {
    font-size: 15px;
    font-size: 1.5rem;
  }
}
.contact-lead__ttl sup {
  color: #ac0926;
  vertical-align: top;
  font-size: 100%;
}
.contact-lead__desc {
  margin-top: 40px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.8666666667;
}
@media screen and (max-width: 640px) {
  .contact-lead__desc {
    margin-top: 20px;
    text-align: left;
  }
}

.contact-form {
  padding-bottom: 100px;
}
@media screen and (max-width: 834px) {
  .contact-form {
    padding-bottom: 60px;
  }
}
.contact-form__wrap {
  margin-bottom: 90px;
  padding: 120px 8.33% 25px;
  background: #fcfcfc;
}
@media screen and (max-width: 640px) {
  .contact-form__wrap {
    margin-bottom: 45px;
    padding: 50px 5% 10px;
  }
}
.contact-form input, .contact-form button, .contact-form textarea, .contact-form select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.contact-form input:active, .contact-form input:focus, .contact-form button:active, .contact-form button:focus, .contact-form textarea:active, .contact-form textarea:focus, .contact-form select:active, .contact-form select:focus {
  outline: 0;
}
.contact-form button {
  cursor: pointer;
}
.contact-form fieldset,
.contact-form legend {
  margin: 0;
  padding: 0;
  border: 0;
}
.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=number],
.contact-form input[type=tel],
.contact-form textarea {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  padding: 25px 3.5%;
  font-size: 18px;
  font-size: 1.8rem;
  resize: none;
  outline: none;
  background: #fff;
  border: 1px solid #ddd;
}
@media screen and (max-width: 640px) {
  .contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=number],
.contact-form input[type=tel],
.contact-form textarea {
    padding: 15px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.contact-form input[type=submit] {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.contact-form input[type=radio],
.contact-form input[type=checkbox] {
  width: 0;
}
.contact-form textarea {
  height: 180px;
  line-height: 1.5;
}
.contact-form :placeholder {
  color: #646464;
}
@media screen and (max-width: 640px) {
  .contact-form :placeholder {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
.contact-form .btn-address {
  display: inline-block;
  margin-left: 20px;
  width: 160px;
  letter-spacing: 0.05em;
  line-height: 33px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
@media screen and (min-width: 835px) {
  .contact-form .btn-address {
    transition: all 0.2s;
  }
  .contact-form .btn-address:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 640px) {
  .contact-form .btn-address {
    width: 130px;
  }
}
.contact-form .error {
  margin: 0 0 10px;
  color: #ac0926;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 13px;
  font-size: 1.3rem;
}
@media screen and (max-width: 640px) {
  .contact-form .error {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.flow-block {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 auto 125px;
  max-width: 452px;
}
@media screen and (max-width: 640px) {
  .flow-block {
    margin: 0 auto 50px;
  }
}
.flow-block:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50px;
  left: 0;
  background: linear-gradient(90deg, #1dd5e6 0%, #46aef7 100%);
}
@media screen and (max-width: 640px) {
  .flow-block:before {
    top: 32px;
  }
}
.flow-block__item {
  width: 100px;
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .flow-block__item {
    width: 65px;
  }
}
.flow-block__item.active .flow-block__no {
  color: #fff;
}
.flow-block__item.active .flow-block__no:before {
  display: none;
}
.flow-block__no {
  position: relative;
  display: block;
  margin-bottom: 15px;
  color: #3bb8f3;
  font-size: 57px;
  font-size: 5.7rem;
  width: 100px;
  height: 100px;
  line-height: 90px;
  background: linear-gradient(90deg, #1dd5e6 0%, #46aef7 100%);
  border-radius: 50%;
}
@media screen and (max-width: 640px) {
  .flow-block__no {
    margin-bottom: 10px;
    width: 65px;
    height: 65px;
    line-height: 60px;
    font-size: 30px;
    font-size: 3rem;
  }
}
.flow-block__no:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
  background: #fff;
  border-radius: 50%;
}
.flow-block__no span {
  position: relative;
}
.flow-block__txt {
  font-size: 18px;
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .flow-block__txt {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.contact-block {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
}
.contact-block__line {
  margin-bottom: 65px !important;
}
@media screen and (max-width: 640px) {
  .contact-block__line {
    margin-bottom: 30px !important;
  }
}
.contact-block__line-head {
  display: block;
  margin-bottom: 15px !important;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 640px) {
  .contact-block__line-head {
    font-size: 15px;
    font-size: 1.5rem;
  }
}
.contact-block__line-head sup {
  color: #ac0926;
  vertical-align: top;
  font-size: 100%;
}
.contact-block__line-cont {
  font-size: 18px;
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .contact-block__line-cont {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.contact-block .contact-lead__ttl {
  margin-bottom: 90px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .contact-block .contact-lead__ttl {
    margin-bottom: 45px;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: left;
  }
}

.contact-control {
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
}
.contact-control__txt {
  margin-bottom: 20px;
  line-height: 1.4;
}
@media screen and (max-width: 640px) {
  .contact-control__txt {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2;
  }
}
.contact-control__link {
  display: inline-block;
  padding-bottom: 6px;
  border-bottom: 1px solid #333;
}
@media screen and (max-width: 640px) {
  .contact-control__link {
    padding-bottom: 0;
  }
}
.contact-control .c-checkbox {
  margin-bottom: 40px;
}
.contact-control .error {
  margin: 10px 0 0;
}

.c-select {
  position: relative;
  width: 100%;
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px solid #ddd;
}
.c-select:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 35px;
  border-style: solid;
  border-width: 8px 7px 0 7px;
  border-color: #333 transparent transparent transparent;
}
@media screen and (max-width: 640px) {
  .c-select:before {
    font-size: 16px;
    font-size: 1.6rem;
    right: 15px;
  }
}
.c-select select {
  position: relative;
  display: inline-block;
  padding: 25px 3.5%;
  width: 100%;
  height: 70px;
  cursor: pointer;
  color: inherit;
}
@media screen and (max-width: 640px) {
  .c-select select {
    padding: 15px;
    height: 55px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.c-checkbox label {
  position: relative;
  display: inline-block;
  padding: 1px 0 1px 25px;
  cursor: pointer;
  text-align: left;
}
.c-checkbox label > span {
  display: inline-block;
}
.c-checkbox label > span:before {
  background: #fff;
  border: 1px solid #000;
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 1px;
  background: #fff;
}
.c-checkbox label > span:after {
  border-right: 2px solid #ce2424;
  border-bottom: 2px solid #ce2424;
  content: "";
  display: block;
  height: 12px;
  width: 6px;
  opacity: 0;
  position: absolute;
  top: 1px;
  left: 5px;
  transform: rotate(45deg);
}
.c-checkbox label input[type=checkbox] {
  width: 0;
  height: 0;
}
.c-checkbox label input[type=checkbox]:checked + span:after {
  opacity: 1;
}

.c-btn__02 {
  display: inline-block;
  position: relative;
  margin: 0 10px 15px;
  max-width: 336px;
  width: 100%;
}
@media screen and (min-width: 835px) {
  .c-btn__02 {
    transition: all 0.2s;
  }
  .c-btn__02:hover {
    opacity: 0.7;
  }
}
.c-btn__02 input {
  padding: 21px;
  color: #fff;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  background: #1dd5e6;
  background: linear-gradient(270deg, #1dd5e6 0%, #46aef7 100%);
  border-radius: 35px;
}
.c-btn__02--en {
  position: relative;
  padding: 21px;
  max-width: 336px;
  width: 100%;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  background: #1dd5e6;
  background: linear-gradient(270deg, #1dd5e6 0%, #46aef7 100%);
  border-radius: 35px;
}
@media screen and (min-width: 835px) {
  .c-btn__02--en {
    transition: all 0.2s;
  }
  .c-btn__02--en:hover {
    opacity: 0.7;
  }
}
.c-btn__back {
  display: inline-block;
  position: relative;
  margin: 0 10px 15px;
  max-width: 336px;
  width: 100%;
}
@media screen and (min-width: 835px) {
  .c-btn__back {
    transition: all 0.2s;
  }
  .c-btn__back:hover {
    opacity: 0.7;
  }
}
.c-btn__back input {
  padding: 21px;
  color: #fff;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  background: #adadad;
  border-radius: 35px;
}

.modal-privacy {
  max-width: 890px;
  text-align: left;
}
.modal-privacy__ttl {
  margin-bottom: 50px;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .modal-privacy__ttl {
    margin-bottom: 30px;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.3;
  }
}
.modal-privacy__block {
  margin-top: 50px;
}
@media screen and (max-width: 640px) {
  .modal-privacy__block {
    margin-top: 30px;
  }
}
.modal-privacy__descTtl {
  margin-bottom: 25px;
  padding-bottom: 6px;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.4;
  border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 640px) {
  .modal-privacy__descTtl {
    margin-bottom: 10px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.c-txt__03 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .c-txt__03 {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.8666666667;
  }
}

.mw_wp_form_confirm .btn-address {
  display: none;
}
.mw_wp_form_confirm .c-select {
  padding: 0;
  border: 0;
  font-size: 18px;
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .mw_wp_form_confirm .c-select {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.mw_wp_form_confirm .c-select:before {
  display: none;
}
.mw_wp_form_confirm .contact-control__txt,
.mw_wp_form_confirm .contact-control .c-checkbox {
  display: none;
}
.mw_wp_form_confirm .contact-lead__ttl span,
.mw_wp_form_confirm .contact-lead__desc {
  display: none;
}

.mw_wp_form.mw_wp_form_input .c-btn__back {
  display: none;
}

@media screen and (min-width: 641px) {
  #page-contact_complete .flow-block {
    margin: 0 auto 90px;
  }
}
#page-contact_complete .contact-form__wrap {
  margin-bottom: 0;
  padding-bottom: 50px;
}
@media screen and (min-width: 641px) {
  #page-contact_complete .contact-form__wrap {
    padding-bottom: 100px;
  }
}