/* ====================================================
Control
==================================================== */
.c-fc{ //font-color
	&--ff{
		color: #fff
	}
}



.c-ft{//font-family
	&--en{
		font-family: $font-en-P;
	}
}



.c-fw{//font-weight
	&--lt{
		font-weight: 300!important
	}
	&--rg{
		font-weight: 400!important
	}
	&--md{
		font-weight: 500!important
	}
	&--sbd{
		font-weight: 600!important
	}
	&--bd{
		font-weight: 700!important
	}
	&--bk{
		font-weight: 900!important
	}
}



.c-tx--v {//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	&-pc{
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}



.c-al{//text-align
	&--c{
		text-align: center!important;
	}
	
	&--r{
		text-align: right!important
	}
	
	&--l{
		text-align: left
	}
}



.c-val{//vertical-align
	&--md{
		vertical-align: middle
	}
	&--top{
		vertical-align: top
	}
}



// Scroll Box
.c-scroll-box{
	padding-bottom: 20px;
	overflow-x: scroll;
	overflow-y: hidden;
	&-y{
		padding-bottom: 20px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	&-sp{
		@include mq(){
			padding-bottom: 20px;
			overflow-x: scroll;
			overflow-y: hidden;
		}
	}
}



// Image Objec Fit
.c-img-of{
	width: 100%;
	height: 100%;
	@include object-fit(cover, center)
}


// Image position control
.c-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}










/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt{
	&__01{//16/35
		@include fts(16,35);
		@include mq(sm) {
			line-height: 1.8;
		}
	}
	&__02{//16/30
		@include fts(16,30);
		@include mq(sm) {
			line-height: 1.8;
		}
	}
}



// Common Title style

.c-ttl{
	&__01{
		@include font-size(48);
		@include font-fm($font-jpMin,500);
		@include mq(sm) {
			@include font-size(28);
			font-size: c-max-sizeCalc(28px, 60);
		}
		&-cap{
			display: block;
			margin-top: 13px;
			@include font-size(18);
			@include mq(sm) {
				@include font-size(15);
			}
		}
	}
	&__03{
		text-align: center;
		&-en{
			@include color-grd;
			@include font-size(16);
			font-family: $font-en-P;
			font-weight: 600;
			@include mq(sm) {
				@include font-size(14);
			}
		}
		&-jp{
			display: block;
			margin-top: 10px;
			@include font-fm($font-jpMin, 500); 
			@include font-size(42);
			letter-spacing: .05em;
			@include mq(sm) {
				@include font-size(32);
			}
		}
		&-jp2{
			display: block;
			margin-top: 10px;
			@include font-fm($font-jpMin, 500); 
			@include font-size(36);
			line-height: 1.44;
			@include mq(sm) {
				@include font-size(25);
			}
		}
		&-jp3{
			display: block;
			margin-top: 10px;
			@include font-fm($font-jpMin, 500); 
			@include font-size(32);
			line-height: 1.44;
			@include mq(sm) {
				@include font-size(24);
			}
		}
		&-cap{
			display: block;
			margin: 25px 0;
			@include font-fm($font-jpMin, 500); 
			@include font-size(21);
			letter-spacing: .08em;
			@include mq() {
				margin: 15px 0;
			}
		}
	}
}



// Common Button style
.c-btn__shine{
	position: relative;
	overflow: hidden;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -75%;
		width: 50%;
		height: 100%;
		background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
		transform: skewX(-25deg);
	}
	&:hover:before {
		animation: shine 0.7s;
	}
	@keyframes shine {
		100% {
			left: 125%;
		}
	}
}



.c-btn__01{
	width: 100%;
	max-width: 310px;
	color: #fff;
	@include font-size(18);
	@include font-fm($font-jpMin, 500); 
	line-height: 65px;
	text-align: center;
	background: #3bb8f3;
	border-radius: 40px;
	@include fn-hover;
	@include mq() {
		@include font-size(16);
		line-height: 50px;
	}
}


  



