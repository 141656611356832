@charset "utf-8";
@import "vars";//setting style and Shortcut css writing
@import "reset";//Basically uneditable document
@import "base";//html,body (and common element) setting
@import "module";//Common content and block style(layout component)
@import "component";//Common button, title, text setting(small component)
@import "utility";//PC, SP switching, Common Animation, hover action, change appearance by JS except for Header and Footer



/* ====================================================
Module
==================================================== */
@import "header";//header style Only
@import "footer";//footer style Only



/* ====================================================
Pages
==================================================== */
@import "index";
@import "recruit";
@import "contact";


