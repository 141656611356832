/* ====================================================
Layout
==================================================== */
.wrapper{
	@include mq(ipd){
		padding-top: 70px;
	}
}

.inner{
	width: 90%;
	margin: 0 auto;
	max-width: 1000px;
	@include mq() {
		width: 100%;
		padding: 0 5%
	}
}


.l-pageTtl{
	text-align: center;
	position: relative;
	background: url(../img/common/bg-pageTtl.jpg) repeat center center;
	background-size: 100%;
	&__ttl{
		position: absolute;
		@include v-h-align;
		@include font-fm($font-jpMin, 500); 
		@include font-size(36);
		font-weight: 500;
		@include mq(sm) {
			@include font-size(25);
		}
	}
	&__image{
		@include mq(sm) {
			width: 85%;
		}
	}
}




