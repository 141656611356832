/* ====================================================
Header
==================================================== */
.main-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 150px;
	z-index: 1000;
	transition: .3s;
	@include mq(ipd) {
		padding: 0 0 0 10px;
		height: 70px;
	}
	&__inner{
		@include flex(start, start ,wrap);
		margin: 0 auto;
	}
	&__logo{
		display: inline-block;
		padding: 38px 0; 
		width: 150px;
		height: 150px;
		text-align: center;
		background: #fff;
		transition: .3s;
		@include mq(ipd) {
			padding: 10px 0;
			width: 70px;
			height: 70px;
			z-index: 100;
			img{
				width: 40px;
			}
		}
	}
	&__navGlobal{
		padding-top: 40px;
		width: calc(100% - 150px);
		@include mq(ipd) {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			padding-top: 70px;
			width: 100%;
			text-align: center;
		}
		&.show{
			display: block;
			background: #fff;
			height: 100vh;
		}
	}
	&__menu{
		@include mq(ipd-min) {
			@include flex(end, center);
		}
		&-item{
			margin-left: 2em;
			@include font-size(15);
			@include mq(xl){
				margin-left: 1em;
			}
			@include mq(ipd) {
				margin: 20px 0;
				@include font-size(16);
			}
			&--twitter{
				margin: 0 3em;
				a{
					color: #3bb8f3;
					@include font-size(16);
				}
			}
		}
	}
	&__link{
		display: inline-block;
		@include fn-hover;
	}	
	&__mobile-icon {
		position: absolute;
		top: 0;
		right: 0;
		display: none;
		width: 70px;
		height: 70px;
		background: #46aef7;
		transition: background .5s;
		z-index: 102;
		cursor: pointer;
		@include mq(ipd){
			display: block;
		}
		span{
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			display: block;
			width: 26px;
			height: 2px;
			font-size: 0;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			transition: background-color 0.3s;
			//background-color: #fff;
			&:before,&:after{
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #fff;
				content: '';
				-webkit-transition: -webkit-transform 0.3s;
				transition: transform 0.3s;
				border-radius: 3px;
			}
			&:before{
				top: -5px;
			}
			&:after{
				bottom: -5px;
			}
		}
		&.mobile-close span {
			background: transparent;
			&:before,&:after{
				width: 100%;
			}
			&:before {
				-webkit-transform: translateY(0) rotate(45deg);
				transform: translateY(0) rotate(45deg);
				top: 0;
			}
			&:after {
				-webkit-transform: translateY(0) rotate(-45deg);
				transform: translateY(0) rotate(-45deg);
				bottom: 0;
			}
		}
	}
	

//active	
	&.is-active{
		background: #fff;
		box-shadow: 0 0 10px rgba(#000, .1);
		@include mq(ipd-min) {
		height: 100px;
		.main-header__logo{
			padding: 13px 0;
			width: 100px;
			height: 100px;
			img{
				width: 60px;
			}
		}
		}
	}
}

