
/* ====================================================
PAGE INDEX
==================================================== */
#page-index{
	padding-bottom: 84px;
	@include mq(sm) {
		padding-bottom: 65px;
	}
}

.top-lead{
	padding: 198px 0 0;
	background: #f3f7f9;
	overflow: hidden;
	@include mq() {
		padding: 80px 0 10px;
	}
	&__ttl{
		text-align: center;
		&-en{
			display: inline-block;
			//@include color-grd;
			color: #3bb8f3;
			@include font-size(100);
			font-family: $font-en-G;
			font-weight: 600;
			letter-spacing: .15em;
			line-height: .7;
			@include mq(sm) {
				@include font-size(55);
			}
		}
		&-jp{
			//position: relative;
			//top: -.3em;
			display: block;
			margin-top: 15px;
			@include font-fm($font-jpMin, 500); 
			@include font-size(60);
			//line-height: .9;
			letter-spacing: -.1em;
			//opacity: .1;
			@include mq(sm) {
				@include font-size(40);
			}
			> span{
				//@include color-grd;
				color: #3bb8f3;
			}
		}
	}
	&__txt{
		margin: 170px 0;
		@include font-fm($font-jpMin, 500); 
		@include fts(32,50);
		font-weight: 500;
		text-align: center;
		@include mq(sm) {
			margin: 40px 0 60px;
			@include font-size(22);
		}
	}
	&__block{
		@include flex(between, center, wrap);
		margin-bottom: 90px;
		@include mq() {
			margin-bottom: 45px;
		}
		%__top-lead__block-content{
			width: 50%;
			@include mq() {
				width: 100%;
			}
		}
		%__top-lead__block-image{
			width: calc(50% + 40px);
			@include mq() {
				margin-bottom: 20px;
				width: 100%;
			}
		}
		&-imageL{
			@extend %__top-lead__block-image;
			@include mq(md-min) {
				padding: 0 40px 0 0;
				margin: 0 0 0 -40px;
			}
		}
		&-imageR{
			@extend %__top-lead__block-image;
			@include mq(md-min) {
				padding: 0 0 0 40px;
				margin: 0 -40px 0 0;
				order: 2;
			}
			@include mq() {
				order: 1;
			}
		}
		&-contentR{
			@extend %__top-lead__block-content;
		}
		&-contentL{
			@extend %__top-lead__block-content;
			order: 1;
			@include mq() {
				order: 2;
			}
		}
		&-en{
			margin-bottom: 10px;
			color: #3bb8f3;
			text-align: center;
			@include font-size(20);
			@include mq(sm) {
				@include font-size(16);
			}
			.en01{
				@include font-fm($font-en-G, 600); 
				@include font-size(24);
				letter-spacing: .1em;
				@include mq(sm) {
					@include font-size(18);
				}
			}
			.en02{
				@include font-fm($font-en-P, 600); 
				@include font-size(26);
				letter-spacing: .1em;
				@include mq(sm) {
					@include font-size(20);
				}
			}
		}
		&-jp{
			margin-bottom: 15px;
			@include font-fm($font-jpMin, 500); 
			@include fts(28,42);
			text-align: center;
			@include mq(sm) {
				margin-bottom: 15px;
				@include font-size(22);
			}
		}
	}	
}

.top-questionnaire{
	padding: 158px 0 100px;
	text-align: center;
	background: #1dd5e6;
	background: linear-gradient(90deg, rgba(29,213,230,1) 0%, rgba(70,174,247,1) 100%);
	@include mq() {
		padding: 60px 0;
	}
	%__top-questionnaire__block01{
		background: #fff;
		border-radius: 20px;
	}
	&__lead{
		margin: 35px 0 90px; 
		color: #fff;
		@include mq() {
			margin: 25px 0 40px; 
		}
	}
	&__block{
		&-ttl{
			@include font-fm($font-jpMin,500);
			@include font-size(24);
			@include mq(sm) {
				@include font-size(20);
			}
			span{
				display: inline-block;
				padding: 18px 0 18px 70px;
				text-align: left;
				background: url(../img/index/icn-q.png) no-repeat left center;
				background-size: 60px;
				@include mq(sm) {
					padding: 15px 0 15px 55px;
					background-size: 50px;
				}
			}
		}
		&-note{
			margin-top: 5px;
			@include font-size(14);
			line-height: 1.3;
		}
	}
	&__block01{
		padding-top: 50px;
		padding-bottom: 30px;
		margin-bottom: 65px;
		@extend %__top-questionnaire__block01;
		@include mq() {
			padding-top: 30px;
			margin-bottom: 30px;
		}
		@include mq(sm) {
			padding: 30px 5%;
		}
		img{
			position: relative;
			//bottom: -40px;
			right: -20px;
			//margin-top: -60px;
			@include mq() {
				display: inline-block;
				margin-top: 20px;
				right: -2%;
				//bottom: -20px;
				//right: -3%;
				//margin-top: -30px;
			}
			//@include mq(sm) {
			//	bottom: 0;
			//	right: 0;
			//	margin-top: 0;
			//}
		}
	}
	&__block02{
		padding: 70px 4% 40px;
		@extend %__top-questionnaire__block01;
		@include mq() {
			padding: 30px 5% 10px;
		}
		.top-questionnaire__block-ttl{
			margin-bottom: 60px;
			@include mq() {
				margin-bottom: 30px;
			}
		}
		//&-image{
		//	margin-right: -5.3%;
		//}
		img{
			width: 100%;
			max-width: 1040px;
			@include mq() {
			  width: 80%;
			}
		}
	}
}


.top-team{
	padding: 130px 0;
	overflow: hidden;
	@include mq() {
		padding: 60px 0 30px;
	}
	&__inner{
		position: relative;
	}
	&__bgImage01{
		position: absolute;
		bottom: 140px;
		left: calc(((1500px - 100%) / 2) * -1);
		width: 354px;
		z-index: -1;
		@include mq(xl) {
			width: 29.5vw;
			bottom: 23%;
			left: calc(((100vw - 100%) / 2) * -1);
		}
		@include mq() {
			bottom: 55%;
			min-width: 200px;
		}
	}
	&__bgImage02{
		position: absolute;
		bottom: 170px;
		right: calc(((1500px - 100%) / 2) * -1);
		width: 310px;
		z-index: -1;
		@include mq(xl) {
			width: 25.833vw;
			bottom: 20%;
			right: calc(((100vw - 100%) / 2) * -1);
		}
		@include mq() {
			min-width: 200px;
			bottom: 25%;
		}
	}
	&__ttl{
		span{
			@include color-grd;
		}
	}
	&__mainImage{
		display: block;
		margin: 70px 0;
		border-radius: 20px;
		overflow: hidden;
		@include mq() {
			margin: 30px 0;
		}
	}
	&__block{
		@include flex(between, start, wrap);
	}
	&__clm{
		@include flex(between, start, wrap);
		width: 49%;
		@include mq() {
			margin-bottom: 30px;
			width: 100%;
		}
		&-img{
			width: 40.0816%;
			overflow: hidden;
			@include mq(sm) {
				margin-bottom: 15px;
				width: 100%;
				text-align: center;
			}
			img{
				border-radius: 20px;
				@include mq(sm) {
					width: 200px;
				}
			}
		}
		&-cont{
			width: 55.78%;
			@include mq(sm) {
				width: 100%;
			}
		}
		&-ttl{
			@include mq(sm) {
				text-align: center;
			}
			&-jp{
				@include font-fm($font-jpMin,500);
				@include font-size(32);
				letter-spacing: .08em;
				@include mq(sm) {
					@include font-size(24);
				}
			}
			&-en{
				display: block;
				margin: 10px 0;
				color: #3bb8f3;
				font-family: $font-en-P;
				@include font-size(12);		
			}
		}
		&-desc{
			@include fts(15,30);
			@include mq(sm) {
				line-height: 1.8;
			}
		}
	}
}

.top-work{
	padding: 105px 0 90px;
	background: url(../img/index/bg-work.jpg) repeat left center;
	background-size: 100%;
	@include mq() {
		padding: 50px 0;
	}
	&__lead{
		margin: 50px 0 65px;
		@include font-fm($font-jpMin,500);
		@include fts(21,34);
		text-align: center;
		@include mq(sm) {
			margin: 30px 0;
			@include font-size(17);		
		}
	}
	&__list{
		@include flex(between, start, wrap);
		margin-top: 50px;
		@include mq(sm) {
			margin-top: 30px;
		}
		&-item{
			@include flex(center,center);
			margin-bottom: 15px;
			width: 18.735833%;
			height: 224.83px;
			text-align: center;
			background: #fff;
			border-radius: 20px;
			@include mq(xl) {
				height: 16.8616vw;
			}
			@include mq() {
				width: 32%;
				height: 28.799vw;
			}
			@include mq(sm) {
				width: 49%;
				height: 44.098vw;
			}
			&-cap{
				margin-top: 28px;
				color: #3bb8f3;
				@include font-size(19);
				font-weight: 500;		
				@include mq(ipd) {
					margin-top: 10px;
				}
				@include mq(sm) {
					@include font-size(16);
				}
			}
			&-icn{
				width: 94px;
				@include mq(ipd) {
					width: 55%;
				}
			}
		}
		&-item-L{
			@include flex(between, start, wrap);
			margin-top: 20px;
			padding: 40px 2.5%;
			width: 49.166%;
			background: #fff;
			border-radius: 20px;
			@include mq() {
				padding: 30px 3%;
				width: 100%;
			}
			&-icn{
				width: 138px;
				@include mq(ipd) {
					width: 100px;
				}
				@include mq(sm) {
					width: 90px;
				}
			}
			&-desc{
				width: calc(100% - 163px);
				@include mq(ipd) {
					width: calc(100% - 110px);
				}
				@include mq(sm) {
					width: calc(100% - 100px);
				}
			}
			&-cap{
				margin-bottom: 5px;
				color: #3bb8f3;
				@include font-size(21);
				font-weight: 700;		
				@include mq(sm) {
					@include font-size(18);
				}
			}
			&-desc{
				@include fts(16,28);
			}
		}
	}
}


.top-product{
	position: relative;
	padding: 116px 0 100px;
	background: url(../img/index/bg-product.jpg) repeat-x center bottom;
	overflow: hidden;
	@include mq() {
		padding: 60px 0 50px;
	}
	&__ttl{
		margin: 0 0 50px;
		@include mq(sm) {
			margin: 0 0 30px;
		}
	}
	&__lead{
		margin: 50px 0 65px;
		@include font-fm($font-jpMin,500);
		@include fts(21,34);
		text-align: center;
		@include mq(sm) {
			margin: 30px 0;
			@include font-size(17);		
		}
	}
	&__block{
		@include flex(between, 0, wrap);
		margin-bottom: 40px;
		&-image{
			width: 32.5%;
			border-radius: 10px;
			overflow: hidden;
			@include mq() {
				margin-bottom: 30px;
				width: 100%;
			}
		}
		&-content{
			padding-bottom: 40px;
			width: 61.667%;
			border-bottom: 1px solid #000;
			@include mq() {
				padding-bottom: 30px;
				width: 100%;
			}
		}
		&-label{
			&-item{
				position: relative;
				display: inline-block;
				margin-bottom: 5px;
				margin-right: 20px;
				padding-left: 20px;
				@include font-fm($font-jpMin,500);
				@include font-size(16);
				@include mq() {
					padding-left: 15px;
					@include font-size(13);
				}
				&:before{
					position: absolute;
					left: 0;
					top: 0;
					color: #3bb9f2;
					@include ft-awesome(\f00c);	
				}
			}
		}
		&-ttl{
			margin: 0 0 20px;
			@include font-fm($font-jpMin,500);
			@include fts(32,34);
			@include mq() {
				@include font-size(24);
			}
		}
		&-desc{
			margin-bottom: 20px;
		}
	}
	&__speclist{
		&-item{
			margin-bottom: 10px;
			color: #aaa;
			@include font-size(12);
			line-height: 1.2;
		}
	}
	&__slide-wrap{
		margin-bottom: 20px;
		position: relative;
		.slick-arrows{
			position: absolute;
			@include v-h-align;
			@include flex(between, center);
			//max-width: 1100px;
			max-width: 960px;
			width: 96%;
			.slick-arrow{
				position: relative;
				width: 65px;
				height: 65px;
				@include mq(sm) {
					width: 45px;
					height: 45px;
				}
				&.slick-prev{
					&:before{
						left: 10px;
						border-left: 2px solid #e2e3e3;
						transform: rotate(-45deg);
					}
				}
				&.slick-next{
					&:before{
						right: 10px;
						border-right: 2px solid #e2e3e3;
						transform: rotate(45deg);
					}
				}
				@include p-before(48px,48px){
					top: 10px;
					text-align: center;
					border-top: 2px solid #e2e3e3;
					@include mq(sm) {
						width: 35px;
						height: 35px;
					}
				}
			}
		}
	}
	&__slide{
		margin-top: 225px;
		padding: 55px 0 0;
		@include mq(xl) {
			padding: 4.583vw 0 0;
		}
		@include mq() {
			margin-top: 60px;
		}
		&-item{
			//max-width: calc(770px + 7.738vw);
			//padding: 0 3.869vw;
			//padding: 0 7.738vw;
			padding: 0 2vw;
			img{
				border-radius: 20px;
			}	
		}
		.slick-slide{
			//padding: 0 6.547619vw;
			//padding: 0 7.74vw;
		}
		.slick-list{
			overflow: visible;
			img{
				//transform: scale(.8194);
				//transform: scale(.8194);
				display: block;
				width: auto;
				max-width: 770px;
				transform: scale(.8);
				transition: .3s;
				@include mq(xl) {
					max-width: 64.166vw;
				}
			}
		}
		.slick-center{
			//transform: scale(1.0742);
			//max-width: 55.95vw;
			img{
				width: 100%;
				//transform: scale(1.22);
				transform: scale(1);
			}
		}
	}
	.slick-dots{
		margin: 0 auto;
		max-width: 750px;
		text-align: right;
		bottom: -20px;
		right: 0;
		left: 0;
		@include mq(lg) {
			max-width: 90%;
		}
		
		li{
			margin: 0 4px;
			button{
				width: 12px;
				height: 12px;
				background: #dddddd;
				border-radius: 50%;
				&:before{
					display: none;
				}
			}
			&.slick-active{
				button{
					background: #3bb8f3;
				}
			}
		}
	}
}

.top__equipment{
	padding-top: 80px;	
	@include mq() {
		padding-top: 40px;	
	}
	&-lead{
		@include flex(between, start, wrap);
		margin-bottom: 70px;
		@include mq() {
			margin-bottom: 40px;
		}
	}
	&-ttl{
		text-align: left;
		width: 47%;
		@include mq() {
			margin-bottom: 15px;
			width: 100%;
		}
	}
	&-desc{
		width: 50%;
		@include mq() {
			width: 100%;
		}
	}
	&-content{
		@include flex(between, start, wrap);
	}
	&-clm{
		width: 46.66%;
		@include mq() {
			width: 100%;
			&:not(:last-child){
				margin-bottom: 30px;
			}
		}
	}
	&-list{
		@include font-size(15);
		line-height: 1.3;
		&-item{
			position: relative;
			padding-left: 20px;
			margin-bottom: 10px;
			@include p-before(10px,10px){
				left: 0;
				top: 5px;
				background: #fff;
				border-radius: 50%;

			}
		}
		&-ttl{
			position: relative;
			margin-bottom: 24px;
			padding-left: 24px;
			@include font-fm($font-jpMin, 500); 
			@include font-size(18);
			letter-spacing: .05em;
			@include mq() {
				margin-bottom: 15px;
			}
			@include p-before(17px,17px){
				left: 0;
				top: 0;
				background: linear-gradient(90deg, rgba(29,213,230,1) 0%, rgba(70,174,247,1) 100%);
				border-radius: 50%;

			}
		}
	}
}


.top-news{
	padding: 152px 0 54px;
	@include mq() {
		padding: 60px 0;
	}
	&__content{
		@include flex(between, start, wrap);
	}
	&__ttl-block{
		margin-left: -67px;
		width: 25.88%;
		text-align: right;
		@include mq(xxl) {
			margin-left: -4.44vw;
		}
		@include mq() {
			margin-left: 0;
			width: 100%;
			text-align: left;
			img{
				display: none;
			}
		}
	}
	&__ttl{
		@include font-fm($font-jpMin, 500); 
		@include font-size(36);
		@include mq() {
			@include font-size(30);
		}
	}
	&__btn{
		display: inline-block;
		margin: 30px 0 50px;
		@include font-size(15);
		@include mq() {
			margin: 20px 0 30px;
		}
		i{
			margin-right: 5px;
			color: #1da1f2;
		}
		span{
			display: inline-block;
			padding-bottom: 5px;
			border-bottom: 1px solid #adadad;
		}
	}
	&__timeline{
		width: 70.166%;
		border-top: 1px solid #b8b8b8;
		@include mq() {
			width: 100%;
		}
		.twitter-timeline{
			@include mq() {
				height: 400px!important;
			}
			@include mq(sm) {
				height: 300px!important;
			}
		}
		
	}
}



.top-company{
	padding: 120px 0 130px;
	background: url(../img/index/bg-company.jpg) repeat center top;
	@include mq() {
		padding: 60px 0;
	}
	&__tab-list{
		@include flex(between, start, wrap);
		margin-top: 100px;
		padding: 0 7.5%;
		border-bottom: 1px solid #ccc;
		@include mq() {
			margin-top: 50px;
			padding: 0;
		}
		&-item{
			position: relative;
			padding-bottom: 25px;
			width: 25%;
			@include font-fm($font-jpMin,500);
			@include font-size(21);
			text-align: center;
			cursor: pointer;
			@include mq() {
				padding-bottom: 20px;
				@include font-size(14);
			}
			@include mq(sm) {
				width: 23%;
				&:last-child{
					width: 31%;
				}
			}
			@include p-before(100%, 3px){
				display: none;
				bottom: -1px;
				left: 0;
				background: #3bb8f3;
			}
			&.active{
				color: #3bb8f3;
				&:before{
					display: block;
				}
			}
		}
	}
	&__outline{
		@include flex(between, start, wrap);
		padding: 60px 0 0;
		@include mq() {
			padding: 20px 0 0;
		}
		&-clm{
			width: 48.33%;
			@include mq() {
				width: 100%;
			}
		}
		&-block{
			@include flex(between, start, wrap);
			padding: 25px 0;
			border-bottom: 1px solid #eee;
			@include mq() {
				padding: 20px 0;
			}
			&-head{
				width: 104px;
				@include font-fm($font-jpMin,500);
				@include font-size(16);
				line-height: 2;
				@include mq() {
					@include font-size(15);
					line-height: 1.6;
				}
			}
			&-desc{
				width: calc(100% - 104px);
				@include font-size(15);
				line-height: 2;
				font-weight: 500;
				@include mq() {
					line-height: 1.6;
				}
				.small{
					@include font-size(13);
				}
			}
		}
	}
	&__history{
		padding-top: 70px;
		@include mq() {
			padding-top: 45px;
		}
		&-block{
			position: relative;
			@include flex(between, start, wrap);
			&:last-of-type{
				padding-bottom: 0;
				.top-company__history-block-head{
					&:before{
						display: none;
					}
				}
			}
			&-head{
				position: relative;
				padding-bottom: 35px;
				padding-right: 45px;
				width: 194px;
				@include font-fm($font-jpMin,500);
				@include font-size(18);
				line-height: 1.3;
				text-align: right;
				@include mq() {
					padding-right: 30px;
					width: 140px;
					@include font-size(15);
				}
				@include mq(sm){
					padding-bottom: 45px;
				}
				@include p-before(1px,100%){
					right: 9px;
					top: 4px;
					background: #ddd;
					@include mq() {
						top: 3px;
						right: 8px;
					}
				}
				@include p-after(18px,18px){
					position: absolute;
					right: 0;
					top: 4px;
					background: #3bb8f3;
					border-radius: 50%;
					@include mq() {
						top: 3px;
						width: 16px;
						height: 16px;
					}
				}
			}
			&-desc{
				padding-left: 24px;
				width: calc(100% - 194px);
				@include font-size(16);
				line-height: 1.5;
				@include mq() {
					padding-left: 15px;
					width: calc(100% - 140px);
					@include font-size(14);
					line-height: 1.3;
				}
			}
		}
	}
	&__philosophy{
		padding-top: 60px;
		@include mq() {
			padding-top: 45px;
		}
		%__block{
			background: #fff;
			border-radius: 20px;
		}
		&-block01{
			margin-bottom: 40px;
			padding: 40px 20px 50px;
			@extend %__block;
			@include mq() {
				margin-bottom: 30px;
				padding: 30px 5%;
			}
		}
		&-block02{
			padding: 50px 5%;
			@extend %__block;
			@include mq() {
				padding: 30px 5%;
			}
		}
		&-ttl{
			margin-bottom: 45px;
			@include mq() {
				margin-bottom: 30px;
			}
			&-jp{
				@include font-fm($font-jpMin,500);
				@include font-size(32);
				letter-spacing: .06em;
				@include mq() {
					@include font-size(26);
				}
			}
			&-en{
				display: block;
				margin-top: 10px;
				color: #3bb8f3;
				@include font-fm($font-en-P,500);
				@include font-size(12);
			}
		}
		&-txt{
			@include font-fm($font-jpMin,500);
			@include font-size(28);
			@include mq(sm) {
				@include font-size(22);
			}
		}
		&-txt02{
			margin-bottom: 25px;
			@include font-fm($font-jpMin,500);
			@include font-size(21);
			line-height: 1.3;
			@include mq(sm) {
				@include font-size(18);
			}
		}
	}
	&__message{
		@include flex(between, start, wrap);
		padding-top: 105px;
		@include mq(sm) {
			padding-top: 45px;
		}
		&-ttl{
			width: 48%;
			text-align: left;
			line-height: 1.4;
			@include mq() {
				margin-bottom: 30px;
				width: 100%;
			}
		}
		&-desc{
			width: 50%;			
			@include mq() {
				width: 100%;
			}
		}
		&-sign{
			margin-top: 30px;
			@include font-size(14);
			text-align: right;
			.strong{
				@include font-size(21);
				@include mq(sm) {
					@include font-size(19);
				}
			}
		}
	}
}



.bnr-contact{
	//display: none;
	position: fixed;
	right: 0;
	bottom: -5px;
	width: 255px;
	@include fn-hover;
	opacity: 0;
	visibility: hidden;
	@include mq(sm) {
		width: 200px;
	}
	&.is-show{
		//display: block;
		opacity: 1;
		visibility: visible;
	}
}















