/* ====================================================
 CONTACT
==================================================== */
.contact-lead{
	padding: 165px 0 115px;
	@include mq() {
		padding: 40px 0;
	}
	&__ttl{
		@include font-fm($font-jpMin, 500); 
		@include font-size(21);
		font-weight: 500;
		letter-spacing: .08em;
		line-height: 1.7;
		@include mq() {
			@include font-size(18);
		}
		span{
			@include font-size(18);
			@include mq() {
				@include font-size(15);
			}
		}
		sup{
			color: #ac0926;
			vertical-align: top;
			font-size: 100%;
		}
	}
	&__desc{
		margin-top: 40px;
		@include fts(15,28);
		@include mq(sm) {
			margin-top: 20px;
			text-align: left;
		}
	}
}

.contact-form{
	padding-bottom: 100px;
	@include mq() {
		padding-bottom: 60px;
	}
	&__wrap{
		margin-bottom: 90px;
		padding: 120px 8.33% 25px;
		background: #fcfcfc;
		@include mq(sm) {
			margin-bottom: 45px;
			padding: 50px 5% 10px;
		}
	}
	@include formReset();
	input[type="text"],
	input[type="email"],
	input[type="number"],
	input[type="tel"],
	textarea{
		display: inline-block;
		width: 100%;
		margin-bottom: 10px;
		padding: 25px 3.5%;
		@include font-size(18);
		resize: none;
		outline: none;
		background: #fff;
		border: 1px solid #ddd;
		@include mq(sm) {
			padding: 15px;
			@include font-size(16);
		}
	}
	input[type="submit"]{
		display: inline-block;
		width: 100%;
		cursor: pointer;
	}
	input[type=radio],
	input[type=checkbox]{
		width: 0;
	}
	textarea{
		height: 180px;
		line-height: 1.5;
	}
	:placeholder{
		color: #646464;
		@include mq(sm) {
			@include font-size(14);
		}
	}
	.btn-address{
		display: inline-block;
		margin-left: 20px;
		width: 160px;
		letter-spacing: .05em;
		line-height: 33px;
		text-align: center;
		border-radius: 20px;
		box-shadow: 2px 2px 8px 0px rgba(#000,.1);
		cursor: pointer;
		@include fn-hover;
		@include mq(sm) {
			width: 130px;
		}
	}

	.error{
		margin: 0 0 10px;
		color: #ac0926;
		font-family: $font-jp;
		font-weight: 400;
		@include font-size(13);
		@include mq(sm) {
			@include font-size(12);
		}
	}
}

.flow-block{
	position: relative;
	@include flex(between, start);
	margin: 0 auto 125px;
	max-width: 452px;
	@include mq(sm) {
		margin: 0 auto 50px;
	}
	@include p-before(100%, 1px){
		top: 50px;
		left: 0;
		background: linear-gradient(90deg, rgba(29,213,230,1) 0%, rgba(70,174,247,1) 100%);
		@include mq(sm) {
			top: 32px;
		}
	}
	&__item{
		width: 100px;
		@include font-fm($font-jpMin, 500); 
		text-align: center;
		@include mq(sm) {
			width: 65px;
		}
		&.active{
			.flow-block__no{
				color: #fff;
				&:before{
					display: none;
				}
			}
		}
	}
	&__no{
		position: relative;
		display: block;
		margin-bottom: 15px;
		color: #3bb8f3;
		@include font-size(57);
		width: 100px;
		height: 100px;
		line-height: 90px;
		background: linear-gradient(90deg, rgba(29,213,230,1) 0%, rgba(70,174,247,1) 100%);
		border-radius: 50%;
		@include mq(sm) {
			margin-bottom: 10px;
			width: 65px;
			height: 65px;
			line-height: 60px;
			@include font-size(30);
		}
		@include p-before(calc(100% - 2px), calc(100% - 2px)){
			top: 1px;
			left: 1px;
			background: #fff;
			border-radius: 50%;
		}
		span{
			position: relative;
		}
	}
	&__txt{
		@include font-size(18);
		@include mq(sm) {
			@include font-size(15);
		}
	}
}


.contact-block{
	@include font-fm($font-jpMin, 500); 
	&__line{
		margin-bottom: 65px!important;
		@include mq(sm) {
			margin-bottom: 30px!important;
		}
		&-head{
			display: block;
			margin-bottom: 15px!important;
			@include font-size(18);
			letter-spacing: .08em;
			@include mq(sm) {
				@include font-size(15);
			}
			sup{
				color: #ac0926;
				vertical-align: top;
				font-size: 100%;
			}
		}
		&-cont{
			@include font-size(18);
			@include mq(sm) {
				@include font-size(16);
			}
		}
		
	}
	.contact-lead__ttl{
		margin-bottom: 90px;
		text-align: center;
		@include mq(sm) {
			margin-bottom: 45px;
			@include font-size(16);
			text-align: left;
		}
	}
}


.contact-control{
	text-align: center;
	@include font-size(16);
	&__txt{
		margin-bottom: 20px;
		line-height: 1.4;
		@include mq(sm) {
			@include font-size(15);
			line-height: 2;
		}
	}
	&__link{
		display: inline-block;
		padding-bottom: 6px;
		border-bottom: 1px solid #333;
		@include mq(sm) {
			padding-bottom: 0;
		}
	}
	.c-checkbox{
		margin-bottom: 40px;
	}
	.error{
		margin: 10px 0 0;
	}
}


.c-select{
	position: relative;
	width: 100%;
	@include font-size(18);
	border: 1px solid #ddd;
	@include p-before(0, 0){
		top: 50%;
	    right: 35px;
	    border-style: solid;
		border-width: 8px 7px 0 7px;
		border-color: #333 transparent transparent transparent;
		@include mq(sm) {
			@include font-size(16);
	    	right: 15px;
		}
	}
	select{
		position: relative;
		display: inline-block;
		padding: 25px 3.5%;
		width: 100%;
		height: 70px;
		cursor: pointer;
		color: inherit;
		@include mq(sm) {
			padding: 15px;
			height: 55px;
			@include font-size(16);
		}
	}
}


.c-checkbox{
	label{
		position: relative;
		display: inline-block;
		padding: 1px 0 1px 25px;
		cursor: pointer;
		text-align: left;
		> span{
			display: inline-block;
			&:before {
			    background: #fff;
			    border: 1px solid #000;
			    content: '';
			    display: block;
			    width: 16px;
			    height: 16px;
			    position: absolute;
			    left: 0;
			    top: 1px;
			    background: #fff;
			}
			&:after {
			    border-right: 2px solid $color-or;
			    border-bottom: 2px solid $color-or;
			    content: '';
			    display: block;
			    height: 12px;
			    width: 6px;
			    opacity: 0;
			    position: absolute;
			    top: 1px;
			    left: 5px;
			    transform: rotate(45deg);
			}
		}
		input[type=checkbox]{
			width: 0;
			height: 0;
			&:checked + span:after {
		    	opacity: 1;
		    }
		}
	}
}


.c-btn{
	&__02{
		display: inline-block;
		position: relative;
		margin: 0 10px 15px;
		max-width: 336px;
		width: 100%;
//		@include p-before(54px, 1px){
//			@include v-align;
//			right: 25px;
//			background: #fff;
//		}
//		@include p-after(5px, 5px){
//			@include v-align;
//			right: 25px;
//			background: #fff;
//			border-radius: 50%;
//		}
		@include fn-hover;
		input{
			padding: 21px;
			color: #fff;
			@include font-size(18);
			font-weight: 500;
			background: rgb(29,213,230);
			background: linear-gradient(270deg, rgba(29,213,230,1) 0%, rgba(70,174,247,1) 100%);
			border-radius: 35px;
		}
		&--en{
			position: relative;
			padding: 21px;
			max-width: 336px;
			width: 100%;
			color: #fff;
			font-family: $font-en-P;
			@include font-size(18);
			font-weight: 500;
			text-align: center;
			background: rgb(29,213,230);
			background: linear-gradient(270deg, rgba(29,213,230,1) 0%, rgba(70,174,247,1) 100%);
			border-radius: 35px;
			//@include p-before(54px, 1px){
			//	@include v-align;
			//	right: 25px;
			//	background: #fff;
			//}
			//@include p-after(5px, 5px){
			//	@include v-align;
			//	right: 25px;
			//	background: #fff;
			//	border-radius: 50%;
			//}
			@include fn-hover;
		}
	}
	&__back{
		display: inline-block;
		position: relative;
		margin: 0 10px 15px;
		max-width: 336px;
		width: 100%;
		@include fn-hover;
		input{
			padding: 21px;
			color: #fff;
			@include font-size(18);
			font-weight: 500;
			background: #adadad;
			border-radius: 35px;
		}
	}
}


.modal-privacy{
	max-width: 890px;
	text-align: left;
	&__ttl{
		margin-bottom: 50px;
		@include font-size(24);
		font-weight: 500;
		text-align: center;
		@include mq(sm) {
			margin-bottom: 30px;
			@include font-size(20);
			line-height: 1.3;
		}
	}
	&__block{
		margin-top: 50px;
		@include mq(sm) {
			margin-top: 30px;
		}
	}
	&__descTtl{
		margin-bottom: 25px;
		padding-bottom: 6px;
		@include font-size(24);
		line-height: 1.4;
		border-bottom: 1px solid #ddd;
		@include mq(sm) {
			margin-bottom: 10px;
			@include font-size(18);
		}
	}
}

.c-txt__03{
	@include fts(18,36);
	@include mq(sm) {
		@include fts(15,28);
	}
}


.mw_wp_form_confirm{
	.btn-address{
		display: none;
	}
	.c-select{
		padding: 0;
		border: 0;
		@include font-size(18);
		@include mq(sm) {
			@include font-size(16);
		}
		&:before{
			display: none;
		}
	}
	.contact-control{
		&__txt,
		.c-checkbox{
			display: none;
		}
	}
	.contact-lead__ttl span,
	.contact-lead__desc{
		display: none;
	}
	
}


.mw_wp_form.mw_wp_form_input{
	.c-btn__back{
		display: none;
	}
}

#page-contact_complete{
	.flow-block{
		@include mq(sm-min) {
			margin: 0 auto 90px;
		}
	}
	.contact-form__wrap{
		margin-bottom: 0;
		padding-bottom: 50px;
		@include mq(sm-min) {
			padding-bottom: 100px;
		}
	}
}