/* ====================================================
 RECRUIT
==================================================== */
.recruit-lead{
	padding: 165px 0 175px;
	@include mq() {
		padding: 60px 0;
	}
	&__ttl{
		@include font-fm($font-jpMin, 500); 
		@include font-size(48);
		font-weight: 500;
		letter-spacing: .08em;
		line-height: 1.3;
		@include mq() {
			@include font-size(26);
			font-size: c-max-sizeCalc(26px, 48);
		}
	}
	&__txt{
		margin: 50px 0 120px;
		@include font-fm($font-jpMin, 500); 
		@include fts(21,36);
		@include mq(sm) {
			margin: 30px 0 40px;
			@include font-size(16);
		}
	}
}

.recruit__bnr{
	padding: 55px 20px;
	text-align: center;
	background: #fefefe url(../img/recruit/bg-bnr.jpg) no-repeat center center;
	background-size: 100%;
	border-radius: 20px;
	box-shadow: 3px 0 10px rgba(#000,.02);
	overflow: hidden;
	@include mq() {
		background: #fefefe url(../img/recruit/bg-bnr-sp.jpg) no-repeat center center;
		background-size: 100%;
	}
	@include mq(sm) {
		padding: 40px 20px;
	}
	&-jp{
		@include font-fm($font-jpMin, 500); 
		@include font-size(35);
		font-weight: 500;
		@include mq(sm) {
			@include font-size(24);
		}
	}
	&-en{
		display: block;
		margin: 20px 0;
		color: #3bb8f3;
		@include font-size(12);
		@include font-fm($font-en-P,600);
	}
	&-link{
		padding: 0 40px;
		color: #fff;
		@include font-fm($font-jpMin, 500); 
		@include font-size(21);
		line-height: 48px;
		font-weight: 500;
		background: #3bb8f3;
		border-radius: 30px;
		@include mq(sm) {
			@include font-size(16);
		}
	}
}






