/* ====================================================
Utility
==================================================== */
.pc {
	@include mq() {
		display: none
	}
}
.sp {
	@include mq(md-min){
		display: none
	}
}
.pc-sm {
	@include mq(sm){
		display: none
	}
}
.sp-sm {
	@include mq(sm-min){
		display: none
	}
}



// Hover style
.u-fn-hover{
	display: inline-block;
	@include mq(md-min){
		transition: all .2s;
		&:hover{
			opacity: .7;
		}
	}
}

.u-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}


//JS scroll event
.js-u__fadein {
	opacity: 0;
	transform: translate(0, 50px);
	transition: all 1000ms;
}

.js-u__fadein.is-visible {
 	opacity: 1;
 	transform: translate(0, 0);
}

.js-u__topfadein {
	opacity: 0;
	visibility: hidden;
	transform: translate(0, 50px);
	transition: all 1000ms;
}

.js-u__topfadein.is-visible {
 	opacity: 1;
 	visibility: visible;
 	transform: translate(0, 0);
}


//JS tab
.js-tab-panel{
	display: none;
	transition: .5s;
	visibility: hidden;
	opacity: 0;
	&.is-show{
		display: block;
		visibility: visible;
		opacity: 1;
	}
}


.eachTextAnime span{opacity: 0;}
.eachTextAnime.appeartext span{ animation:text_anime_on 1s ease-out forwards; }

.eachTextAnime2 span{opacity: 0;}
.eachTextAnime2.appeartext span{ animation:text_anime_on 1s ease-out forwards; }

@keyframes text_anime_on {
	0% {opacity:0;}
	100% {opacity:1;}
}


