/* ====================================================
Footer
==================================================== */
.main-footer{
	&__function{
		padding: 80px 0 70px;
		color: #fff;
		background: rgb(29,213,230);
		background: linear-gradient(90deg, rgba(29,213,230,1) 0%, rgba(70,174,247,1) 100%);
		@include mq(sm){
			padding: 40px 0 10px;
		}
	}
	&__function{
		&-content{
			@include flex(between, 0, wrap);
		}
		&-clm{
			padding: 74px 2%;
			width: 50%;
			text-align: center;
			@include mq(sm){
				margin-bottom: 30px;
				padding: 0;
				width: 100%;
			}
			@include mq(sm-min){
				&:first-child{
					border-right: 1px solid rgba(#fff, .3);
				}
			}
		}
		&-txt{
			margin-bottom: 40px;
			@include font-fm($font-jpMin, 500); 
			@include font-size(21);
			line-height: 1.4;
			font-weight: 500;
			@include mq(lg){
				@include font-size(17);
			}
			@include mq(sm){
				margin-bottom: 15px;
			}
		}
		&-btn{
			display: inline-block;
			width: 100%;
			max-width: 390px;
			@include font-fm($font-jpMin, 500); 
			@include font-size(16);
			line-height: 92px;
			font-weight: 500;
			border-radius: 50px;
			box-shadow: 4px 0 7px rgba(0,0,0,.03);
			background: #fff;
			@include fn-hover;
			@include mq(sm){
				@include font-size(15);
				line-height: 60px;
			}
			> span{
				@include color-grd;
			}
		}
	}
	
	
	&__copyright{
		padding: 35px 0;
		color: #c3d4d8;
		font-family: $font-en-P;
		text-align: center;
		@include mq(sm){
			padding: 20px 0;
		}
	}
}


